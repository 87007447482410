<template>
  <div class="section-container regular-text">
    <div class="position-relative  pb-3 pb-md-0 ">
      <div class="text-container">
        <p class="mb-0">
          Si estás buscando una forma rápida y segura de obtener dinero sin renunciar a tu coche, este artículo es para ti. Descubre cómo los <b>préstamos por tu auto</b> pueden ser la solución ideal en situaciones de emergencia financiera.
        </p>

        <span class="bold-text">
          <br>
          <h2 style="font-size: 20px;">Préstamos por tu auto, una alternativa moderna y flexible</h2>
        </span>
        <p>
          Los <b>préstamos sobre auto</b> ofrecen una manera sencilla y efectiva de acceder a financiamiento sin perder el control de tu vehículo. A diferencia de los empeños tradicionales, en Ibancar México el coche se utiliza como garantía sin cambiar de propietario, lo que significa que seguirás manejándolo durante el préstamo.
        </p>
        <p>
          Con un proceso 100% online y la posibilidad de obtener el dinero en menos de 24 horas, Ibancar se ha posicionado como una alternativa innovadora para quienes necesitan liquidez de forma urgente.
        </p>

        <span class="bold-text">
          <br>
          <h2 style="font-size: 20px;">Todo lo que necesitas saber sobre los préstamos con garantía de coche</h2>
        </span>
        <p>
          En Ibancar, el procedimiento es transparente y diseñado para maximizar tu comodidad. Aquí te explicamos las claves:
        </p>

        <span class="bold-text">
          <br>
          <h3 style="font-size: 20px;">¿De cuánto importe pueden ser los préstamos por carros?</h3>
        </span>
        <p>
          El importe de los <b>préstamos por factura de coche</b> puede llegar hasta el 70% del valor de tu vehículo. Este porcentaje depende de factores como el modelo, el estado del coche y el historial financiero del solicitante.
        </p>
        <p>
          Además, Ibancar permite pagos anticipados con una penalización mínima, lo que te da flexibilidad para ajustar tu financiamiento a tus necesidades.
        </p>
        <br/>
        <p>
          <strong>Proceso rápido y sencillo</strong>
        </p>
        <ul>
          <li><b>Solicitud online:</b> Desde la comodidad de tu casa, puedes iniciar el proceso en minutos.</li>
          <li><b>Evaluación personalizada:</b> Proporciona los datos del coche y tus documentos personales (INE, nómina o comprobante de ingresos, y fotos del coche).</li>
          <li><b>Propuesta inmediata:</b> Recibe una oferta sin compromiso y, si la aceptas, firma digitalmente para agilizar el ingreso del dinero.</li>
          <li><b>Dinero en tu cuenta:</b> Una vez aprobada la solicitud, tendrás el dinero en menos de 24 horas.</li>
        </ul>
        <br>

        <p>
          <strong>Beneficios exclusivos</strong>
        </p>
        <ul>
          <li>Las mensualidades son fijas y comienzas a aportar a capital desde el primer pago.</li>
          <li>Ibancar también ofrece servicios como revisiones técnicas anuales, flexibilidad en fechas de pago y un gestor personal para resolver tus dudas.</li>
        </ul>
        <br>

        <span class="bold-text">
          <br>
          <h2 style="font-size: 20px;">¿Por qué elegir Ibancar?</h2>
        </span>
        <p>
          La principal ventaja de elegir Ibancar radica en su enfoque como <b>alternativa al empeño tradicional</b>. Esto significa que:
        </p>
        <ul>
          <li><b>Sigues siendo el propietario del coche.</b></li>
          <li>El proceso es completamente online, ahorrándote desplazamientos.</li>
          <li>No tendrás que preocuparte por pagos finales sorpresa ni alquileres por usar tu propio coche.</li>
        </ul>
        <p>
          Además, Ibancar está diseñado para personas que enfrentan dificultades de acceso crediticio, como quienes aparecen en listas de morosidad, ofreciendo una solución accesible y flexible.
        </p>
        <br>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PrestamoGarantiaAutoS2",
  components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.text-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.regular-text {
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 30px;
}

.title {
  font-size: 25px;
  line-height: 30px;
}

.bold-text {
  font-family: "Montserrat-Bold";
}

.text-orange {
  color: $orange;
}

.text-blue {
  color: $blue;
}

.text-black {
  color: $black;
}

.text-underline {
  text-decoration: underline;
}

.background-orange-3 {
  background-color: $orange-4;
}

.btn-solicitar {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

// Image
.image {
  bottom: 0;
  right: 0;
  width: 30%;
  z-index: -1;
}

@include media-breakpoint-down(md) {
  .text-container {
    width: 90%;
  }

  .regular-text {
    line-height: 1.5;
  }

  ul {
    padding-inline-start: 20px;
  }

  ol {
    padding-inline-start: 20px;
  }
}

@include media-breakpoint-between(md, lg) {
  .regular-text {
    font-size: 16px;
  }

  .title {
    font-size: 28px;
    line-height: 35px;
  }

  .subtitle {
    font-size: 16px;
  }
}

@include media-breakpoint-down(sm) {
  .title {
    font-size: 20px;
    line-height: 25px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 20px;
  }

  .regular-text {
    font-size: 16px !important;
  }

  .section-container {
    margin-top: 1.5rem;
  }
}
</style>