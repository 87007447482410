<template>
  <div class="section-container regular-text">
    <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-2">
      <div class="text-container">
        <div class="">
          <span class="bold-text">
            <br>
            <h2 style="font-size: 20px;">Preguntas frecuentes (FAQS)</h2>
          </span>
          <br>
          <div>
              <h3 style="font-size: 20px; font-style: italic;">-  ¿Puedo solicitar el préstamo si mi coche tiene una antigüedad considerable?</h3>
              <p style="font-size: 17px;">Sí, mientras el coche cumpla con los requisitos mínimos y esté en buen estado, puedes utilizarlo como garantía. Envía el formulario en un minuto y descubre cuánto te podemos ofrecer.</p>
          </div>
          <br>
          <div>
              <h3 style="font-size: 20px; font-style: italic;">- ¿Qué pasa si no puedo pagar el préstamo?</h3>
              <p style="font-size: 17px;">En caso de no poder cumplir con las cuotas, Ibancar ofrece la opción de vender el coche. El dinero obtenido se usa para saldar la deuda, devolviéndote cualquier excedente.</p>
          </div>
          <br>
          <div>
              <h3 style="font-size: 20px; font-style: italic;">-  ¿Cuánto tiempo tengo para devolver el préstamo?</h3>
              <p style="font-size: 17px;">Los plazos son flexibles y personalizados según tu capacidad de pago. Puedes acordar mensualidades cómodas que incluyan días de gracia.
                <br><br>
                Un préstamo sobre auto con Ibancar es la solución perfecta si necesitas dinero rápido sin perder la propiedad de tu coche. Con más de seis años en el mercado y miles de clientes satisfechos, su propuesta combina velocidad, flexibilidad y confianza.
                <br><br>
                ¡Inicia tu solicitud hoy y recupera el control de tus finanzas sin complicaciones!
                </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  name: "PrestamoGarantiaAutoS4",
  components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.text-dudas {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}
.text-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.regular-text {
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 30px;
}

.title {
  font-size: 25px;
  line-height: 30px;
}

.bold-text {
  font-family: "Montserrat-Bold";
}

.text-orange {
  color: $orange;
}

.text-blue {
  color: $blue;
}

.text-black {
  color: $black;
}

.text-underline {
  text-decoration: underline;
}

.background-orange-3 {
  background-color: $orange-4;
}

.btn-solicitar {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

// Image
.image {
  bottom: 0;
  right: 0;
  width: 30%;
  z-index: -1;
}

@include media-breakpoint-down(md) {
  .text-container {
    width: 90%;
  }

  .regular-text {
    line-height: 1.5;
  }

  ul {
    padding-inline-start: 20px;
  }

  ol {
    padding-inline-start: 20px;
  }
}

@include media-breakpoint-between(md, lg) {
  .regular-text {
    font-size: 16px;
  }

  .title {
    font-size: 28px;
    line-height: 35px;
  }

  .subtitle {
    font-size: 16px;
  }
}

@include media-breakpoint-down(sm) {
  .title {
    font-size: 20px;
    line-height: 25px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 20px;
  }

  .regular-text {
    font-size: 16px !important;
  }
}
</style>