<template>
  <div class="body-container">
    <BannerSection title="Empeño de autos en Ibancar:</br> rápido, seguro y confiable"
                   subtitle="" />
    <PerksComponent></PerksComponent>

    <PrestamoGarantiaAutoS2 />
    <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
      <div class="ibancar-election">
        <p>¿Por qué elegir Ibancar?</p>
      </div>
      <BeneficiosIbancarCarousel />
    </div>
    <PrestamoGarantiaAutoS4 />
    <SolicitaloBannerS2 />
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import PrestamoGarantiaAutoS2 from '@/components/PrestamoGarantiaAuto/PrestamoGarantiaAutoS2.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import PrestamoGarantiaAutoS4 from '@/components/PrestamoGarantiaAuto/PrestamoGarantiaAutoS4.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
  name: 'CreditoCoche',
  components: {
    BannerSection,
    BeneficiosIbancarCarousel,
    PrestamoGarantiaAutoS2,
    PrestamoGarantiaAutoS4,
    SolicitaloBannerS2,
    PerksComponent
  },
  setup() {
    useMeta(
      {
        title: 'Préstamo con garantía de auto',
        meta: [
          {
            name: 'robots',
            content: 'index'
          },
          {
            name: 'description',
            content: 'Préstamo con aval de auto sin cambio de titularidad. Consigue dinero hasta $120,000 en 48h.  Consigue dinero rápido y evita empeñar tu auto. Ibancar México'
          },
          {
            name: 'keywords',
            content: 'prestamo sobre auto - préstamos por tu auto - prestamos por carros - prestamos por factura de carro'
          }
        ],
        link: [
          { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
          { href: 'https://ibancar.mx/prestamo-garantia-auto', rel: 'alternate', hreflang: 'es-MX' }
        ]
      }
    )
  },

  data() {
    return {
      ventajas: [
        {
          image: 'image12.svg',
          title: 'Ejecutivo personalizado',
        },
        {
          image: 'solicitud-en-linea.svg',
          title: 'Proceso simple sin salir de casa',
        },

        {
          image: 'prestamos-48-hrs.svg',
          title: 'Hasta $120,000 en 24H',
        },
        {
          image: 'proceso-simple.svg',
          title: 'Aceptamos personas en Buró.',
        }

      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.banner-container {
  width: 85%;
  max-height: 29rem;
  // background-color: #FFA5000A;

}

.background-benefits {
  // background-color: #FFA5000A;
  margin-bottom: 2rem !important;
}

.card-text {
  font-family: "Montserrat-Medium";
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 16px;
}

.body-container {
  margin-top: 115px;
  min-height: calc(100vh - 115px);
}

.perks-container {

  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.ibancar-election {
  margin: 1rem 2rem 0rem 2rem;

  p {
    color: #004680;
    text-align: center;

    /* Mobile 360 px/H2 */
    font-family: 'Montserrat-Bold';
    font-size: 1.3rem;
    font-style: normal;
    line-height: normal;
    margin: 1.5rem 0rem;
  }
}

@include media-breakpoint-down(md) {

  .banner-container {
    width: 100%;
  }
}

@include media-breakpoint-between(md, lg) {
  .banner-container {
    width: 80%;
  }
}


@include media-breakpoint-down(sm) {
  .body-container {
    margin-top: 84px;
  }

  .perks-container {
    margin: 0rem 1.4rem;
    flex-direction: column;
    // gap: 2rem;
  }

  .container-perks {
    background-color: rgba(255, 165, 0, 0.04);
    margin-bottom: 2rem;
  }

  .ibancar-election {
    p {
      margin: 0;
    }
  }

}

@include media-breakpoint-up(lg) {
  .background-benefits {
    background-color: white;
    margin: 1rem 0rem;
  }

  .card-text {
    font-size: 1rem;
  }

}

.text-blue {
  color: #004680;
}

// Card
.card {
  border-radius: 0.625rem;
  border: 1px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.40));
  background: rgba(255, 255, 255, 0.90);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
}

.card-text {
  font-family: "Montserrat-Medium";
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 17px;
}

@include media-breakpoint-down(sm) {
  .background-benefits {
    background-color: #FFA5000A;
    ;
  }
}

@include media-breakpoint-down(xs) {
  .background-benefits {
    padding: 1rem 0.5rem;
  }
}
</style>